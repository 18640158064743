import React, { useContext } from 'react'
import { css } from 'styled-components'

import CustomQuoteLayout from '../../../views/custom-quote/components/CustomQuoteLayout'
import FormNavigation from '../../../views/custom-quote/components/FormNavigation'
import FormQuestion from '../../../views/custom-quote/components/FormQuestion'
import FormTextInput from '../../../views/custom-quote/components/FormTextInput'

import { CustomQuoteContext } from '../../../views/custom-quote/context/CustomQuoteManager'

import { space } from '../../../utils/tokens'
import { CUSTOM_QUOTE_SEQUENCE_LINKS } from '../../../views/custom-quote/constants'
import { navigate } from 'gatsby'
import FormSubmitButton from '../../../views/custom-quote/components/FormSubmitButton'

const ContactInformation = () => {
  const {
    formData,
    updateFormData,
    submitFormData,
    isDocumentUploading,
    isFilesUploading
  } = useContext(CustomQuoteContext)

  const handleFirstName = (value) => {
    updateFormData({
      ...formData,
      firstName: value,
    })
  }

  const handleLastName = value => {
    updateFormData({
      ...formData,
      lastName: value,
    })
  }

  const handleEmail = value => {
    updateFormData({
      ...formData,
      email: value,
    })
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await submitFormData()
      updateFormData({})
      navigate(`/success/3d-printing/`)
    } catch(error) {
      console.log(error)
    }
  }

  return (
    <CustomQuoteLayout>
      <form name="contact-info" onSubmit={handleSubmit}>
        <div
          css={css({
            marginBottom: `${space[4]}px`,
          })}
        >
          <FormQuestion
            title="First name"
            css={css({
              marginBottom: `${space[3]}px`,
            })}
          />
          <FormTextInput
            value={formData && formData.firstName}
            required
            onChange={handleFirstName}
          />
        </div>
        <div
          css={css({
            marginBottom: `${space[4]}px`,
          })}
        >
          <FormQuestion
            title="Last name"
            css={css({
              marginBottom: `${space[3]}px`,
            })}
          />
          <FormTextInput
            value={formData && formData.lastName}
            onChange={handleLastName}
          />
        </div>
        <div
          css={css({
            marginBottom: `${space[4]}px`,
          })}
        >
          <FormQuestion
            title="Email"
            css={css({
              marginBottom: `${space[3]}px`,
            })}
          />
          <FormTextInput
            required
            type="email"
            onChange={handleEmail}
            value={formData && formData.email}
          />
        </div>
        <FormNavigation
          onSubmit={handleSubmit}
          previousLink={CUSTOM_QUOTE_SEQUENCE_LINKS.contactInformation.previous}
          customButton={
            <FormSubmitButton
              label="Submit"
              isLoading={isFilesUploading || isDocumentUploading}
            />
          }
        />
      </form>
    </CustomQuoteLayout>
  )
}

export default ContactInformation
