import React from 'react'
import { css } from 'styled-components'

import { space, palette } from '../../../utils/tokens'

const FormTextInput = ({
  required,
  value,
  onChange,
  ...rest
}) => {

  const handleChange = (e) => {
    onChange && onChange(e.target.value)
  }

  return (
    <input
      value={value || ''}
      onChange={handleChange}
      css={css({
        borderRadius: `${space[1]}px`,
        border: `1px solid ${palette.grey[50]}`,
        padding: `${space[2]}px`,
        height: `48px`,
        width: `100%`,
        resize: `none`,
      })}
      required={required}
      {...rest}
    />
  )
}

export default FormTextInput